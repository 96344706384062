import React, { Component, useEffect } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import CircleType from 'circletype';
import Faq from '../../components/faq/Faq';
import bird1 from '../../assets/images/birds/bird1.png';
import bird2 from '../../assets/images/birds/bird2.png';
import eye from '../../assets/images/icons/eye.svg';
import compass from '../../assets/images/icons/compass.svg';
import food from '../../assets/images/icons/food.svg';
import mouth from '../../assets/images/icons/mouth.svg';
import sound from '../../assets/images/icons/sound.svg';
import Experience from '../../components/experiences/experience';
// experiences
import raybanden from '../../assets/images/experiences/raybanden.jpg';

// transitionimage
import transitionimage from '../../assets/images/transition.jpg';
// travelines
import Travelline from '../../components/travellines/Travelline1';

// rotate text
import experienceSpinner from '../../assets/images/experiences/experience_spinner.svg';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-column: 1 / span 24;
    @media (min-width: 960px) {
      grid-column: 5 / span 16;
    }
    .experiences-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  .img {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  .text {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  header {
    grid-column: 2 / span 22;
    @media (min-width: 1100px) {
      grid-column: 5 / span 16;
    }

    padding: 3.5vw 0 0 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .back {
      margin-bottom: 20px;
      display: block;
      font-size: 1em;
    }
    .intro-text {
      grid-column: 1 / span 4;
      @media (min-width: 768px) {
        grid-column: 1 / span 3;
      }
      .title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .sub-heading {
        font-size: 1.2rem;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 10px;
      }
      margin-bottom: 40px;
    }
    .top-right {
      grid-column: 6 / span 1;
      @media (min-width: 768px) {
        grid-column: 4 / span 6;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .circle-rotate {
        position: absolute;
        font-size: 0.7em;
        color: var(--olive);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        #circle-text {
          width: 130px;
          height: 130px;
          animation: spin 60s infinite linear;
          max-width: none;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      img {
        width: 60px;
      }
    }
    .right {
      grid-column: 1 / span 6;
      display: flex;
      flex-direction: row;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .img1 {
        flex: 1;

        img {
          display: block;
        }
      }
    }
  }

  // min-height: 100vh;
`;

const Bird2 = styled.div`
  position: absolute;
  left: -70px;
  top: 0%;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;

const Bird3 = styled.div`
  position: absolute;
  right: -70px;
  top: 400px;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;
const ExperiencesStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: masonry;
  grid-gap: 0;
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experiences() {
  useEffect(() => {});
  return (
    <Page>
      <Bird2>
        <img src={bird2} alt="" />
      </Bird2>
      <Bird3>
        <img src={bird1} alt="" />
      </Bird3>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <div className="intro-text">
              <AniLink
                className="link back b-i"
                to="/experiences"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Back to Experiences
              </AniLink>
              <h1>Ray-Ban Studios Den</h1>
              <p className="sub-heading b-i">
                Where the outside comes inside to play.
              </p>
            </div>
            <div className="top-right">
              <div className="circle-rotate">
                <img
                  id="circle-text"
                  className="sb"
                  src={experienceSpinner}
                  alt=""
                />
              </div>
              <img src={sound} alt="" />
            </div>
            <div className="right">
              <div className="img1">
                {' '}
                <img src={raybanden} alt="" />
              </div>
            </div>
          </header>

          <div className="text">
            <p>
              Where the outside comes inside to play. Inspired by the forest and
              the predators that call it home, the Den will host some of the
              finest sounds and styles from the underground.
            </p>
            <p className="i">
              Performances by: <br />
              <br />
              ???????
              <br />
              <AniLink
                className="link back b"
                to="/artists/dj-mocity"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                DJ MoCity
              </AniLink>
              <br />
              <AniLink
                className="link back b"
                to="/artists/shireen"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Shireen
              </AniLink>
              <br />
              <AniLink
                className="link back b"
                to="/artists/chhabb"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Chhabb
              </AniLink>
              <br />
              <AniLink
                className="link back b"
                to="/artists/spacejams"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Spacejams
              </AniLink>
              <br />
              <AniLink
                className="link back b"
                to="/artists/nash"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Nash
              </AniLink>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}
